<template>
  <div>

    <grid-layout
      class="platformCourses"
      ref="gridLayout"
      :tableOptions="tableOptions"
      :tableData="tableData"
      :tableLoading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-size-change="onLoad"
      @page-current-change="onLoad"
      @page-refresh-change="onLoad"
    >
<!--          v-if="isDateBeforeCurrent(row.endTime)"

------------------
          v-if="row.isFinish == 2"
          继续培训

-->
      <template #customBtn="{ row, index }">
<!--        <el-button-->
<!--          size="small"-->
<!--          type="text"-->
<!--          v-if="isDateBeforeCurrent(row.endTime)&&row.status != 3"-->
<!--          @click.stop="supplementaryDutyPersonnel(row, index)"-->
<!--        >-->
<!--          补充任务人员-->
<!--        </el-button>-->

        <el-button
          size="small"
          type="text"
          @click.stop="continuingTraining(row, index)"
          v-if="row.status != 1&&row.status != 3&&isDateBeforeCurrent(row.endTime)"
        >
          继续培训
        </el-button>
        <el-button
          size="small"
          type="text"
          @click.stop="viewResultRecord(row, index)"
        >
          查看结果记录
        </el-button>
      </template>
    </grid-layout>
    <el-drawer
      title="培训记录"
      :visible.sync="trainingEducationStatisticsIndexDrawer"
      v-if="trainingEducationStatisticsIndexDrawer"
      direction="rtl"
      append-to-body
      size="70%">
      <trainingEducationStatisticsIndex ref="trainingEducationStatisticsIndex" :rowData="rowData" type = "trainingEducationStatistics"></trainingEducationStatisticsIndex>
    </el-drawer>
  </div>
</template>

<script>
import {getDetailBySiteTrainingId, searchTrainingRecords} from "@/api/training/recording";
import {companyProjects} from "@/api/system/dept";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import WfFlow from '@/views/plugin/workflow/process/components/flow.vue';
import {mapGetters} from "vuex";
import {dateFormat} from "@/util/date";
import trainingEducationStatisticsIndex from "@/views/business/training/trainingEducationStatistics/index";

export default {
  props: {
    initTree: {
      type: Function,
      default: null
    },
    trainingInformation:{
      type:Object
    }
  },
  data() {
    return {
      rowData:{},
      trainingEducationStatisticsIndexDrawer:false,
      trainingRecordEjectLayer:true,
      query: {
        trainContentCode: "",
      },
      total: "",
      page: {
        pageSize: PAGE_CONSTANT.TWENTY,
        currentPage: 1,
        total: 0
      },
      tableLoading: false,
      tableData: [],
      tableOptions: {
        indexLabel: "序号",
        index: true,
        linklabel: "courseName",
        selection: false,
        menuWidth: 240,
        column: [
          {
            label: "任务名称",
            prop: 'trainTheme',
            align: "left",
            width: 140,
          },
          {
            label: "培训时间",
            prop: "trainingTime",
            align: "center",
            // width: 280,
            overHidden: true,
          },
          {
            label: "发起时间",
            prop: "updateTime",
            align: "center",
            width: 280,
            overHidden: true,
          },
          {
            label: '培训状态',
            prop: "status",
            align: 'center',
            width: '150',
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=training_status",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dataType: "number",
            overHidden: true
          },
        ],
      },
    };
  },
  components: {
    HeadLayout,
    GridLayout,
    WfFlow,
    trainingEducationStatisticsIndex
  },
  computed: {
    ...mapGetters(["permission", "language", "userInfo"]),
    gridRowBtn() {
      let rowBtn = [];
      rowBtn.push({
          label: this.$t("cip.cmn.btn.viewBtn"),
          emit: "grid-view",
          remark: "view",
          type: "text",
          icon: "",
        },
        {
          label: "开始培训",
          emit: "grid-status",
          remark: 'status',
          type: "text",
          icon: ""
        });
      return rowBtn;
    }
  },
  methods: {
    isDateBeforeCurrent(inputDate) {
      // 将传入的年月日字符串转换为Date对象
      var inputDateObj = new Date(inputDate);

      // 获取当前日期
      var currentDate = new Date();

      // 比较日期
      if (inputDateObj < currentDate) {
        return false; // 如果传入日期早于当前日期，false
      } else {
        return true; // 如果传入日期晚于或等于当前日期，true
      }
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      delete this.query.createTime;
      searchTrainingRecords(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query, {id:this.trainingInformation.id})
      ).then(res => {
        const data = res.data.data;
        page.total = data.total;
        //进行数据构造
        data.records.forEach(e=>{
          e.endTime = e.trainEnd.substring(0, 10)
          e.trainingTime = e.trainStart.substring(0, 10)  +" 至 "+ e.trainEnd.substring(0, 10)
        })
        this.tableData = data.records;
        this.$nextTick(() => {
          this.$refs.gridLayout.page.total = data.total;
        })
        this.tableLoading = false;
      });
    },
    /**
     * 查看结果记录
     * @param row
     */
    viewResultRecord(row){
      this.trainingEducationStatisticsIndexDrawer = true
      this.rowData = row
    },
    /**
     * 继续培训
     * @param row
     */
    continuingTraining(row){
      getDetailBySiteTrainingId(row.id).then((res) => {
        if (res.data.code == 200) {
          if (res.data.data) {
            this.$router.push({
              path: '/business/training/onSiteTraining/edit',
              query: {
                row: JSON.stringify(res.data.data),
                type: 'edit',
              }
            });
          }
        }
      })
    },
    /**
     * 补充任务人员
     */
    supplementaryDutyPersonnel(row){

        this.$router.push({
          path: '/business/safetyenment/train/trainingTaskListPageAdd',
          query: {
            id: row.id,
            type: 'supplementaryDutyPersonnel',
          }
        });
    },
  },
  mounted() {
    this.onLoad(this.page);
  },
};
</script>
<style lang="scss" scoped>
.headers {
  background: #ffffff;
  padding: 14px 12px;
}

::v-deep .platformCourses .avue-crud .el-table {
  height: calc(100vh - 316px) !important;
  max-height: calc(100vh - 316px) !important;
}

.headers ::v-deep .el-button {
  height: 28px;
}

.el-button + .el-button {
  margin-left: 5px !important;
}

::v-deep .bjs-powered-by {
  display: none;
}
</style>
